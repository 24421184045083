import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from '@/plugins/vuetify'
import router from './router'
import { loadFonts } from './plugins/webfontloader'
// import '@fortawesome/fontawesome-free/css/all.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from "./axios-auth";
import VueAxios from "vue-axios";
import CKEditor from '@ckeditor/ckeditor5-vue';

import moment from 'moment';
moment.locale('in');


loadFonts()

createApp( App)

.use(VueSweetalert2)
  .use(store)
  .use(router)
  .use(vuetify)
  .use(VueAxios,axios)
  .use(CKEditor)
  .mount('#app')
