// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { VDataTable } from 'vuetify/labs/VDataTable'
import { createVuetify } from 'vuetify'
import colors from 'vuetify/lib/util/colors'

const opts = {
	theme: {
		themes: {
			light: {
				primary: '#3f51b5',
				secondary: '#b0bec5',
				accent: '#8c9eff',
				error: '#b71c1c',
				bg_theme: '#3f51b5'

			},
		},
	},
}

export default createVuetify({
  components: {
    VDataTable,
  },
    theme: {
      defaultTheme: 'myCustomTheme',
      themes: {
        myCustomTheme: {
          dark: false,
          colors: {
            primary: '#3f51b5',
            secondary: '#b0bec5',
            accent: '#8c9eff',
            error: '#b71c1c',
            bg_theme: '#3f51b5'
          }
        }
      }
    }
  })

