import { createWebHistory, createRouter } from 'vue-router'

const router = createRouter({
	history: createWebHistory(),
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '',
			component: () => import('@/components/layout/login'),
			children: [
				{
					path: '', name: 'login', component: () => import('./view/auth/login.vue'),
					meta: {
						page_title: 'Login'
					}
				},
			]
		},
		{
			path: '',
			component: () => import('@/components/layout/dashboard'),
			children: [
				{
					path: '/dashboard', name: 'dashboard', component: () => import('./view/dashboard/dashboard.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Dashboard', disabled: true }
						],
						page_title: 'Dashboard'
					}
				},
				{
					path: '/change-password', name: 'change-passowrd', component: () => import('./view/change-password/change-password.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Change Password', disabled: true },
						],
						page_title: 'Change Password'
					}
				},
				{
					path: '/profile', name: 'profile', component: () => import('./view/auth/profile/profile.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Profile', disabled: true },
						],
						page_title: 'Profile'
					}
				},
				{
					path: '/edit_therapists', name: 'edit-therapists', component: () => import('./view/edit-therapists/edit-therapists.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Edit Therapists', disabled: true },
						],
						page_title: 'Edit Therapists'
					}
				},
				{
					path: '/edit_schools', name: 'edit-schools', component: () => import('./view/edit-schools/edit-schools.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Edit Schools', disabled: true },
						],
						page_title: 'Edit Schools'
					}
				},
				{
					path: '/edit_districts', name: 'edit-districts', component: () => import('./view/edit-district/edit-district.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Edit Schools', disabled: true },
						],
						page_title: 'Edit Schools'
					}
				},
				{
					path: '/assign_coverage', name: 'assign-coverage-to-open-mandates', component: () => import('./view/assign-coverage-to-open-mandates/assign-coverage-to-open-mandates.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Assign Coverage to Open Mandates', disabled: true },
						],
						page_title: 'Assign Coverage to Open Mandates'
					}
				},
				{
					path: '/post_direct_sessions', name: 'post-direct-session', component: () => import('./view/post-direct-session/post-direct-session.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Post Direct Session', disabled: true },
						],
						page_title: 'Post Direct Session'
					}
				},
				{
					path: '/post_consult_sessions', name: 'post-consult-session', component: () => import('./view/post-consult-session/post-consult-session.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Post Consult Session ', disabled: true },
						],
						page_title: 'Post Consult Session'
					}
				},
				{
					path: '/edit_direct_consult_mandates', name: 'edit-direct-consult-mandates', component: () => import('./view/edit-direct-consult-mandates/edit-direct-consult-mandates.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Edit Direct/Consult Mandates', disabled: true },
						],
						page_title: 'Edit Direct/Consult Mandates'
					}
				},
				{
					path: '/post_makeup_sessions', name: 'post-make-up-session', component: () => import('./view/post-make-up-session/post-make-up-session.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Post Make-up Session', disabled: true },
						],
						page_title: 'Post Make-up Session'
					}
				},
				{
					path: '/post_session_notes', name: 'post-session-notes-non-iep', component: () => import('./view/post-session-notes-non-iep/post-session-notes-non-iep.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Post Session Notes (Non-IEP)', disabled: true },
						],
						page_title: 'Post Session Notes (Non-IEP)'
					}
				},
				{
					path: '/post_evaluations', name: 'post-evaluations', component: () => import('./view/post-evaluations/post-evaluations.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Post Evaluations', disabled: true },
						],
						page_title: 'Post Evaluations'
					}
				},
				{
					path: '/post_re_evaluations', name: 'post-re-evaluations', component: () => import('./view/post-re-evaluations/post-re-evaluations.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Post Re-Evaluations', disabled: true },
						],
						page_title: 'Post Re-Evaluations'
					}
				},

				{
					path: '/post_meeting_notes', name: 'post-meeting-notes', component: () => import('./view/post-meeting-notes/post-meeting-notes.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Post Meeting Notes', disabled: true },
						],
						page_title: 'Post Meeting Notes'
					}
				},
				{
					path: '/post_classroom_consult_sessions', name: 'post-classroom-consult-sessions', component: () => import('./view/post-classroom-consult-sessions/post-classroom-consult-sessions.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Post Classroom Consult Sessions', disabled: true },
						],
						page_title: 'Post Classroom Consult Sessions'
					}
				},
				{
					path: '/transactions_by_therapist', name: 'transactions-by-therapist', component: () => import('./view/transactions-by-therapist/transactions-by-therapist.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Transactions By Therapist', disabled: true },
						],
						page_title: 'Transactions By Therapist'
					}
				},
				{
					path: '/transactions_by_student', name: 'transactions-by-student', component: () => import('./view/transactions-by-student/transactions-by-student.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Transactions By Student', disabled: true },
						],
						page_title: 'Transactions By Student'
					}
				},
				{
					path: '/upload-iep-sessions', name: 'upload-iep-sessions', component: () => import('./view/upload-iep-sessions/upload-iep-sessions.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Import IEP Sessions', disabled: true },
						],
						page_title: 'Import IEP Sessions'
					}
				},
				{
					path: '/post_iep_direct_input', name: 'post-iep-direct-input', component: () => import('./view/post-iep-direct-input/post-iep-direct-input.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Post IEP Direct Input', disabled: true },
						],
						page_title: 'Post IEP Direct Input'
					}
				},

				{
					path: '/edit_district_bill_rates', name: 'edit_district_bill_rates', component: () => import('./view/edit-district-bill-rates/edit-district-bill-rates.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Edit District Bill Rates', disabled: true },
						],
						page_title: 'Edit District Bill Rates'
					}
				},
				{
					path: '/post_direct_service_annual_reviews', name: 'post_direct_service_annual_reviews', component: () => import('./view/post-direct-service-annual-reviews/post-direct-service-annual-reviews.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Post Direct Service Annual Reviews', disabled: true },
						],
						page_title: 'Post Direct Service Annual Reviews'
					}
				},
				{
					path: '/edit_therapist_pay_rates', name: 'edit_therapist_pay_rates', component: () => import('./view/edit-therapist-pay-rates/edit-therapist-pay-rates.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Edit Therapist Pay Rates', disabled: true },
						],
						page_title: 'Edit Therapist Pay Rates'
					}
				},
				// {
				// 	path: '/verify_direct_sessions', name: 'verify_direct_sessions', component: () => import('./view/verify-direct-sessions/verify-direct-sessions.vue'),
				// 	meta: {
				// 		breadcrumb: [
				// 			{ title: 'Home', to: '/dashboard' },
				// 			{ title: 'Verify Direct Sessions', disabled: true },
				// 		],
				// 		page_title: 'Verify Direct Sessions'
				// 	}
				// },

				{
					path: '/verify_direct_sessions', name: 'sign_transactions_by_therapist', component: () => import('./view/sign-transactions-by-therapist/sign-transactions-by-therapist.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Sign Transactions By Therapist', disabled: true },
						],
						page_title: 'Sign Transactions By Therapist'
					}
				},
				{
					path: '/monthly_billing_forms', name: 'monthly_billing_forms', component: () => import('./view/monthly-billing-forms/monthly-billing-forms.vue'),
					meta: {
						breadcrumb: [
							{ title: 'Home', to: '/dashboard' },
							{ title: 'Monthly Billing Forms', disabled: true },
						],
						page_title: 'Monthly Billing Forms'
					}
				}
			]
		}
	]
})

router.beforeEach((to, from, next) => {
	document.title = to.meta.page_title;
	next();
});

export default router;